import React, { useState } from 'react'
import cn from 'classnames'
import { times } from 'lodash'
import './App.css'
import logo from './logo.png'

function App() {
  return (
    <div className="h-full text-center">
      <div className="h-full max-w-md mx-auto p-4 flex flex-col">
        <div className="max-w-sm mx-auto mb-3">
          <img className="w-fluid" src={logo} alt="Kingdomino" />
        </div>
        <Calculator />
      </div>
    </div>
  )
}

const Calculator = () => {
  const [properties, setProperties] = useState([])
  const [nextPorpertyId, setNextPorpertyId] = useState(0)
  const [currentEdit, setCurrentEdit] = useState({
    id: null,
    type: 'fields',
  })

  const notEmptyProperties = properties.filter(
    ({ fields, crowns }) => fields !== 0 && crowns !== 0
  )

  if (currentEdit.id === null) {
    return (
      <div className="flex-grow flex flex-col">
        <h1 className="text-4xl mb-4 text-white font-bold text-shadow">
          My Kingdom
        </h1>
        <div className="mb-3">
          {notEmptyProperties.length > 0 ? (
            <table className="text-white text-2xl font-bold text-shadow border-blue-200 text-left w-full border-collapse">
              <thead>
                <tr>
                  <th className="pr-4 py-2 text-xl">Squares</th>
                  <th className="pr-4 py-2 text-xl">Crowns</th>
                  <th className="pr-4 py-2 text-xl">Total</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {notEmptyProperties.map(({ id, fields, crowns }, index) => (
                  <tr key={id} className="border-blue-200 border-b-2">
                    <td className="pr-4 py-2">{fields}</td>{' '}
                    <td className="pr-4 py-2">{crowns}</td>
                    <td className="pr-4 py-2">{fields * crowns}</td>
                    <td className="py-2 text-right">
                      <button
                        onClick={() => {
                          setProperties(
                            properties.filter((property) => property.id !== id)
                          )
                        }}
                        className="ml-3 py-2 px-4 rounded bg-red-500 hover:bg-red-600 text-sm font-bold"
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="2" className="pr-4 py-2">
                    Total score:
                  </td>
                  <td className="py-2">
                    {properties.reduce(
                      (acc, propery) => acc + propery.fields * propery.crowns,
                      0
                    )}
                  </td>
                </tr>
              </tfoot>
            </table>
          ) : (
            <p className="text-white pb-4 text-2xl font-bold text-shadow border-blue-200">
              Add new property by pressing the "Add property" button, count your
              squares then count your crowns!
            </p>
          )}
        </div>
        <div className="flex justify-between mt-auto mb-4">
          <button
            className={cn('bg-red-500 text-white font-bold py-4 px-8 rounded', {
              'opacity-75 cursor-not-allowed': notEmptyProperties.length <= 0,
              'hover:bg-red-600': notEmptyProperties.length > 0,
            })}
            onClick={() => {
              setProperties([])
              setCurrentEdit({ id: null, type: 'fields' })
              setNextPorpertyId(0)
            }}
            disabled={notEmptyProperties.length <= 0}
          >
            Remove all
          </button>
          <Button
            onClick={() => {
              const id = nextPorpertyId
              setProperties([...properties, { id, fields: 0, crowns: 0 }])
              setCurrentEdit({ id, type: 'fields' })
              setNextPorpertyId(nextPorpertyId + 1)
            }}
          >
            Add property
          </Button>
        </div>
      </div>
    )
  }
  if (currentEdit.type === 'fields') {
    return (
      <GetNumber
        title="Number of squares?"
        onSelect={(number) => {
          setProperties(
            properties.map((porperty) =>
              porperty.id === currentEdit.id
                ? { ...porperty, fields: number }
                : porperty
            )
          )
          setCurrentEdit({ id: currentEdit.id, type: 'crowns' })
        }}
        onCancel={() => {
          setCurrentEdit({ id: null, type: 'fields' })
        }}
      />
    )
  }
  if (currentEdit.type === 'crowns') {
    return (
      <GetNumber
        title="Number of crowns? 👑"
        onSelect={(number) => {
          setProperties(
            properties.map((porperty) =>
              porperty.id === currentEdit.id
                ? { ...porperty, crowns: number }
                : porperty
            )
          )
          setCurrentEdit({ id: null, type: 'fields' })
        }}
        onCancel={() => {
          setCurrentEdit({ id: null, type: 'fields' })
        }}
      />
    )
  }
}

const GetNumber = ({ title, onSelect, onCancel }) => {
  const maxNumber = 12

  return (
    <div className="flex-grow flex flex-col">
      <h1 className="text-4xl mb-4 text-white font-bold text-shadow">
        {title}
      </h1>
      <div className="grid grid-cols-3 gap-4 mb-4">
        {times(maxNumber, (index) => (
          <button
            className="flex items-center justify-center bg-yellow-500 text-white text-3xl border-white border-4 font-bold h-24"
            onClick={() => {
              onSelect(index + 1)
            }}
          >
            {index + 1}
          </button>
        ))}
      </div>
      <div className="text-right mt-auto mb-4">
        <Button onClick={onCancel}>Cancel</Button>
      </div>
    </div>
  )
}

const Button = ({ className, ...props }) => (
  <button
    className={cn(
      'bg-blue-700 hover:bg-blue-800 text-white font-bold py-4 px-8 rounded',
      className
    )}
    {...props}
  />
)

export default App
